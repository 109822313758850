export function Logo(): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 184.063 100.088'>
      <defs>
        <path id='rect280849' d='M21.71 20.254H743.806V464.209H21.71z'></path>
      </defs>
      <g transform='translate(-5.493 -11.807)'>
        <g fill='#66d9ef' fillOpacity='1' transform='translate(1.058 1.058)'>
          <g transform='scale(.26458)'>
            <path d='M75.71 60.254v5.333h-32v-5.333z' style={{}}></path>
            <path d='M97.71 60.254v5.333h-32v-5.333z' style={{}}></path>
            <path d='M119.71 60.254v5.333h-32v-5.333z' style={{}}></path>
            <path d='M141.71 60.254v5.333h-32v-5.333z' style={{}}></path>
            <path d='M405.71 60.254v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M37.71 59.834q2.668 0 2.668 3.52V97.7q0 3.52-2.667 3.52t-2.667-3.52V63.354q0-1.707.534-2.613.586-.907 2.133-.907z'
              style={{}}
            ></path>
            <path d='M119.71 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M141.524 58.34l17.12 36.16q.64 1.28.64 2.027 0 1.014-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.813l-17.067-36.214q-.64-1.28-.64-1.973 0-1.013.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.814z'
              style={{}}
            ></path>
            <path d='M207.71 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M229.71 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M251.71 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M317.71 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M339.71 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M361.71 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M365.79 80.1q0-3.68 1.546-8.64 1.547-4.96 3.733-8.533 2.24-3.573 3.947-3.573 1.173 0 1.92.8.8.747.8 1.813 0 .8-.96 2.507-.907 1.653-2.08 3.52-1.12 1.867-2.08 5.227-.907 3.306-.907 7.04 0 7.786 5.387 16.533.64 1.013.64 1.813 0 1.067-.8 1.814-.747.8-1.92.8-1.707 0-3.893-3.414-2.187-3.413-3.787-8.48-1.547-5.066-1.547-9.226z'
              style={{}}
            ></path>
            <path d='M405.71 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M401.95 98.607q0-.8.906-2.506.96-1.654 2.08-3.52 1.173-1.867 2.08-5.174.96-3.36.96-7.093 0-7.787-5.387-16.533-.64-1.014-.64-1.814 0-1.066.747-1.813.8-.8 1.973-.8 1.707 0 3.894 3.413 2.186 3.414 3.733 8.48 1.6 5.067 1.6 9.227 0 3.68-1.547 8.64-1.493 4.907-3.733 8.48-2.187 3.627-3.947 3.627-1.173 0-1.973-.8-.747-.747-.747-1.814z'
              style={{}}
            ></path>
            <path d='M449.71 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M493.709 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M515.709 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M603.708 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M625.708 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path d='M647.708 98.394v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M37.71 97.974q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.613.586-.907 2.133-.907z'
              style={{}}
            ></path>
            <path
              d='M81.71 97.974q2.667 0 2.667 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.613.587-.907 2.134-.907z'
              style={{}}
            ></path>
            <path d='M119.71 136.534v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M115.95 136.747q0-.8.907-2.507.96-1.653 2.08-3.52 1.174-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.386-16.534-.64-1.013-.64-1.813 0-1.066.746-1.813.8-.8 1.974-.8 1.706 0 3.893 3.413 2.187 3.414 3.733 8.48 1.6 5.067 1.6 9.227 0 3.68-1.546 8.64-1.494 4.906-3.734 8.48-2.186 3.626-3.946 3.626-1.174 0-1.974-.8-.746-.746-.746-1.813z'
              style={{}}
            ></path>
            <path
              d='M180.644 98.667l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.747.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path d='M229.71 136.534v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M251.523 96.48l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.866.8-.96 0-1.547-.533-.587-.48-1.227-1.814l-17.066-36.213q-.64-1.28-.64-1.973 0-1.013.8-1.76.8-.8 1.866-.8.907 0 1.494.533.586.48 1.226 1.814z'
              style={{}}
            ></path>
            <path
              d='M290.643 98.667l-17.067 36.213q-.586 1.334-1.226 1.814-.587.533-1.547.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.174-1.813.586-.533 1.546-.533 1.067 0 1.867.8.8.747.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path d='M339.71 136.534v5.333h-32v-5.333z' style={{}}></path>
            <path d='M361.71 136.534v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M367.71 97.974q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.613.587-.907 2.133-.907z'
              style={{}}
            ></path>
            <path
              d='M411.71 97.974q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.613.587-.907 2.133-.907z'
              style={{}}
            ></path>
            <path
              d='M452.296 99.094h6.826l-1.653 13.546q-.16 1.067-.533 1.494-.374.426-1.174.426-1.44 0-1.653-1.866z'
              style={{}}
            ></path>
            <path d='M493.709 136.534v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M515.522 96.48l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.013.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.814z'
              style={{}}
            ></path>
            <path
              d='M576.642 98.667l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.747.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path d='M625.708 136.534v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M647.521 96.48l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.866.8-.96 0-1.547-.533-.587-.48-1.227-1.814l-17.066-36.213q-.64-1.28-.64-1.973 0-1.013.8-1.76.8-.8 1.866-.8.907 0 1.494.533.586.48 1.226 1.814z'
              style={{}}
            ></path>
            <path
              d='M37.71 136.114q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M119.71 174.674v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M158.484 142.62q.906 0 1.546.854.694.8.694 1.6 0 1.386-1.707 2.293l-13.92 7.093 13.92 7.254q1.707.906 1.707 2.293 0 .8-.694 1.653-.64.8-1.546.8-.747 0-1.6-.48l-22.934-11.573 22.934-11.307q.96-.48 1.6-.48z'
              style={{}}
            ></path>
            <path d='M229.71 174.674v5.333h-32v-5.333z' style={{}}></path>
            <path d='M251.71 174.674v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M268.643 136.807l-17.066 36.213q-.587 1.334-1.227 1.814-.587.533-1.547.533-1.066 0-1.866-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.586-1.333 1.173-1.813.587-.533 1.547-.533 1.066 0 1.866.8.8.746.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path
              d='M299.79 156.38q0-3.68 1.546-8.64 1.547-4.96 3.734-8.533 2.24-3.573 3.946-3.573 1.174 0 1.92.8.8.746.8 1.813 0 .8-.96 2.507-.906 1.653-2.08 3.52-1.12 1.866-2.08 5.226-.906 3.307-.906 7.04 0 7.787 5.386 16.534.64 1.013.64 1.813 0 1.067-.8 1.813-.746.8-1.92.8-1.706 0-3.893-3.413t-3.787-8.48q-1.546-5.067-1.546-9.227z'
              style={{}}
            ></path>
            <path d='M339.71 174.674v5.333h-32v-5.333z' style={{}}></path>
            <path d='M361.71 174.674v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M367.71 136.114q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M411.71 136.114q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M455.709 136.114q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M493.709 174.674v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M489.949 174.887q0-.8.906-2.507.96-1.653 2.08-3.52 1.174-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.386-16.534-.64-1.013-.64-1.813 0-1.067.746-1.813.8-.8 1.974-.8 1.706 0 3.893 3.413t3.733 8.48q1.6 5.067 1.6 9.227 0 3.68-1.546 8.64-1.494 4.906-3.734 8.48-2.186 3.626-3.946 3.626-1.174 0-1.974-.8-.746-.746-.746-1.813z'
              style={{}}
            ></path>
            <path
              d='M543.709 136.114q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
              style={{}}
            ></path>
            <path d='M625.708 174.674v5.333h-32v-5.333z' style={{}}></path>
            <path d='M647.708 174.674v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M664.641 136.807l-17.066 36.213q-.587 1.334-1.227 1.814-.587.533-1.547.533-1.066 0-1.866-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.586-1.333 1.173-1.813.587-.533 1.547-.533 1.066 0 1.866.8.8.746.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path
              d='M37.71 174.254q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M75.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M81.71 174.254q2.667 0 2.667 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
              style={{}}
            ></path>
            <path d='M119.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M119.524 172.76l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path d='M163.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M163.524 172.76l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path d='M207.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path d='M229.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path d='M251.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M257.71 174.254q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M273.523 172.76l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path d='M317.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path d='M339.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path d='M361.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M367.71 174.254q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M405.71 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M411.71 174.254q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M456.189 208.28h-.96q-1.44 0-2.507-1.013-1.013-1.067-1.013-2.507 0-1.44 1.013-2.453 1.067-1.067 2.507-1.067h.96q1.493 0 2.507 1.014 1.013 1.013 1.013 2.506 0 1.494-1.013 2.507-1.014 1.013-2.507 1.013z'
              style={{}}
            ></path>
            <path d='M493.709 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path d='M515.709 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M532.642 174.947l-17.067 36.213q-.586 1.334-1.226 1.814-.587.533-1.547.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.174-1.813.586-.533 1.546-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path
              d='M559.522 172.76l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path d='M603.708 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path d='M625.708 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path d='M647.708 212.814v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M653.708 174.254q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M37.71 212.394q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M119.71 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M141.524 210.9l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path d='M207.71 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path d='M229.71 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path d='M251.71 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path d='M295.71 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path d='M339.71 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path d='M361.71 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M411.71 212.394q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M449.71 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M455.709 212.394q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M493.709 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path d='M559.709 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path d='M603.708 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M609.708 212.394q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M653.708 212.394q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M691.708 250.954v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M37.71 250.534q2.668 0 2.668 3.52V288.4q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M81.71 250.534q2.667 0 2.667 3.52V288.4q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
              style={{}}
            ></path>
            <path d='M119.71 289.094v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M115.95 289.307q0-.8.907-2.507.96-1.653 2.08-3.52 1.174-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.386-16.534-.64-1.013-.64-1.813 0-1.067.746-1.813.8-.8 1.974-.8 1.706 0 3.893 3.413t3.733 8.48q1.6 5.067 1.6 9.227 0 3.68-1.546 8.64-1.494 4.906-3.734 8.48-2.186 3.626-3.946 3.626-1.174 0-1.974-.8-.746-.746-.746-1.813z'
              style={{}}
            ></path>
            <path
              d='M180.644 251.227l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path d='M229.71 289.094v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M251.523 249.04l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.866.8-.96 0-1.547-.533-.587-.48-1.227-1.814l-17.066-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.866-.8.907 0 1.494.533.586.48 1.226 1.813z'
              style={{}}
            ></path>
            <path
              d='M298.296 251.654h6.827L303.47 265.2q-.16 1.067-.534 1.494-.373.426-1.173.426-1.44 0-1.653-1.866z'
              style={{}}
            ></path>
            <path d='M339.71 289.094v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M361.523 249.04l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path
              d='M422.643 251.227l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path d='M471.709 289.094v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M493.522 249.04l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path
              d='M521.709 250.534q2.666 0 2.666 3.52V288.4q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
              style={{}}
            ></path>
            <path
              d='M562.295 251.654h6.827l-1.654 13.546q-.16 1.067-.533 1.494-.373.426-1.173.426-1.44 0-1.654-1.866z'
              style={{}}
            ></path>
            <path d='M603.708 289.094v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M609.708 250.534q2.667 0 2.667 3.52V288.4q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M669.708 289.094v5.333h-32v-5.333z' style={{}}></path>
            <path d='M691.708 289.094v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M697.708 250.534q2.667 0 2.667 3.52V288.4q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M37.71 288.674q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M119.71 327.234v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M158.484 295.18q.906 0 1.546.854.694.8.694 1.6 0 1.386-1.707 2.293l-13.92 7.093 13.92 7.254q1.707.906 1.707 2.293 0 .8-.694 1.653-.64.8-1.546.8-.747 0-1.6-.48l-22.934-11.573 22.934-11.307q.96-.48 1.6-.48z'
              style={{}}
            ></path>
            <path d='M229.71 327.234v5.333h-32v-5.333z' style={{}}></path>
            <path d='M251.71 327.234v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M268.643 289.367l-17.066 36.213q-.587 1.334-1.227 1.814-.587.533-1.547.533-1.066 0-1.866-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.586-1.333 1.173-1.813.587-.533 1.547-.533 1.066 0 1.866.8.8.746.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path
              d='M301.71 288.674q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
              style={{}}
            ></path>
            <path d='M339.71 327.234v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M335.95 327.447q0-.8.906-2.507.96-1.653 2.08-3.52 1.174-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.386-16.534-.64-1.013-.64-1.813 0-1.067.746-1.813.8-.8 1.974-.8 1.706 0 3.893 3.413t3.733 8.48q1.6 5.067 1.6 9.227 0 3.68-1.546 8.64-1.494 4.906-3.734 8.48-2.186 3.626-3.946 3.626-1.174 0-1.974-.8-.746-.746-.746-1.813z'
              style={{}}
            ></path>
            <path
              d='M389.71 288.674q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M431.79 308.94q0-3.68 1.546-8.64 1.546-4.96 3.733-8.533 2.24-3.573 3.947-3.573 1.173 0 1.92.8.8.746.8 1.813 0 .8-.96 2.507-.907 1.653-2.08 3.52-1.12 1.866-2.08 5.226-.907 3.307-.907 7.04 0 7.787 5.387 16.534.64 1.013.64 1.813 0 1.067-.8 1.813-.747.8-1.92.8-1.707 0-3.894-3.413-2.186-3.413-3.786-8.48-1.547-5.067-1.547-9.227z'
              style={{}}
            ></path>
            <path d='M471.709 327.234v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M467.949 327.447q0-.8.907-2.507.96-1.653 2.08-3.52 1.173-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.387-16.534-.64-1.013-.64-1.813 0-1.067.747-1.813.8-.8 1.973-.8 1.707 0 3.893 3.413 2.187 3.413 3.734 8.48 1.6 5.067 1.6 9.227 0 3.68-1.547 8.64-1.493 4.906-3.733 8.48-2.187 3.626-3.947 3.626-1.173 0-1.973-.8-.747-.746-.747-1.813z'
              style={{}}
            ></path>
            <path
              d='M521.709 288.674q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
              style={{}}
            ></path>
            <path
              d='M565.708 288.674q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M609.708 288.674q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M653.708 288.674q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M691.708 327.234v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M37.71 326.814q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M75.71 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M81.71 326.814q2.667 0 2.667 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
              style={{}}
            ></path>
            <path
              d='M119.524 325.32l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path d='M163.71 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M163.524 325.32l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path d='M207.71 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path d='M229.71 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path d='M251.71 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M257.71 326.814q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M302.19 360.84h-.96q-1.44 0-2.507-1.013-1.013-1.067-1.013-2.507 0-1.44 1.013-2.453 1.067-1.067 2.507-1.067h.96q1.493 0 2.506 1.014 1.014 1.013 1.014 2.506 0 1.494-1.014 2.507-1.013 1.013-2.506 1.013z'
              style={{}}
            ></path>
            <path d='M339.71 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path d='M361.71 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M378.643 327.507l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path
              d='M405.523 325.32l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path d='M449.71 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path d='M471.709 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path d='M493.709 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M510.642 327.507l-17.067 36.213q-.586 1.334-1.226 1.814-.587.533-1.547.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.174-1.813.586-.533 1.546-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
              style={{}}
            ></path>
            <path
              d='M521.709 326.814q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
              style={{}}
            ></path>
            <path d='M559.709 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M565.708 326.814q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M625.522 325.32l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
              style={{}}
            ></path>
            <path d='M669.708 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path d='M691.708 365.374v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M697.708 326.814q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path
              d='M257.71 364.954q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
              style={{}}
            ></path>
            <path d='M295.71 403.514v5.333h-32v-5.333z' style={{}}></path>
            <path
              d='M301.71 364.954q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
              style={{}}
            ></path>
          </g>
        </g>
      </g>
      <g transform='translate(-5.493 -11.807)'>
        <g fill='#f92672' fillOpacity='1' transform='scale(.26458)'>
          <path d='M75.71 60.254v5.333h-32v-5.333z' style={{}}></path>
          <path d='M97.71 60.254v5.333h-32v-5.333z' style={{}}></path>
          <path d='M119.71 60.254v5.333h-32v-5.333z' style={{}}></path>
          <path d='M141.71 60.254v5.333h-32v-5.333z' style={{}}></path>
          <path d='M405.71 60.254v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M37.71 59.834q2.668 0 2.668 3.52V97.7q0 3.52-2.667 3.52t-2.667-3.52V63.354q0-1.707.534-2.613.586-.907 2.133-.907z'
            style={{}}
          ></path>
          <path d='M119.71 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M141.524 58.34l17.12 36.16q.64 1.28.64 2.027 0 1.014-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.813l-17.067-36.214q-.64-1.28-.64-1.973 0-1.013.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.814z'
            style={{}}
          ></path>
          <path d='M207.71 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M229.71 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M251.71 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M317.71 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M339.71 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M361.71 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M365.79 80.1q0-3.68 1.546-8.64 1.547-4.96 3.733-8.533 2.24-3.573 3.947-3.573 1.173 0 1.92.8.8.747.8 1.813 0 .8-.96 2.507-.907 1.653-2.08 3.52-1.12 1.867-2.08 5.227-.907 3.306-.907 7.04 0 7.786 5.387 16.533.64 1.013.64 1.813 0 1.067-.8 1.814-.747.8-1.92.8-1.707 0-3.893-3.414-2.187-3.413-3.787-8.48-1.547-5.066-1.547-9.226z'
            style={{}}
          ></path>
          <path d='M405.71 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M401.95 98.607q0-.8.906-2.506.96-1.654 2.08-3.52 1.173-1.867 2.08-5.174.96-3.36.96-7.093 0-7.787-5.387-16.533-.64-1.014-.64-1.814 0-1.066.747-1.813.8-.8 1.973-.8 1.707 0 3.894 3.413 2.186 3.414 3.733 8.48 1.6 5.067 1.6 9.227 0 3.68-1.547 8.64-1.493 4.907-3.733 8.48-2.187 3.627-3.947 3.627-1.173 0-1.973-.8-.747-.747-.747-1.814z'
            style={{}}
          ></path>
          <path d='M449.71 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M493.709 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M515.709 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M603.708 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M625.708 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path d='M647.708 98.394v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M37.71 97.974q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.613.586-.907 2.133-.907z'
            style={{}}
          ></path>
          <path
            d='M81.71 97.974q2.667 0 2.667 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.613.587-.907 2.134-.907z'
            style={{}}
          ></path>
          <path d='M119.71 136.534v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M115.95 136.747q0-.8.907-2.507.96-1.653 2.08-3.52 1.174-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.386-16.534-.64-1.013-.64-1.813 0-1.066.746-1.813.8-.8 1.974-.8 1.706 0 3.893 3.413 2.187 3.414 3.733 8.48 1.6 5.067 1.6 9.227 0 3.68-1.546 8.64-1.494 4.906-3.734 8.48-2.186 3.626-3.946 3.626-1.174 0-1.974-.8-.746-.746-.746-1.813z'
            style={{}}
          ></path>
          <path
            d='M180.644 98.667l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.747.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path d='M229.71 136.534v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M251.523 96.48l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.866.8-.96 0-1.547-.533-.587-.48-1.227-1.814l-17.066-36.213q-.64-1.28-.64-1.973 0-1.013.8-1.76.8-.8 1.866-.8.907 0 1.494.533.586.48 1.226 1.814z'
            style={{}}
          ></path>
          <path
            d='M290.643 98.667l-17.067 36.213q-.586 1.334-1.226 1.814-.587.533-1.547.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.174-1.813.586-.533 1.546-.533 1.067 0 1.867.8.8.747.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path d='M339.71 136.534v5.333h-32v-5.333z' style={{}}></path>
          <path d='M361.71 136.534v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M367.71 97.974q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.613.587-.907 2.133-.907z'
            style={{}}
          ></path>
          <path
            d='M411.71 97.974q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.613.587-.907 2.133-.907z'
            style={{}}
          ></path>
          <path
            d='M452.296 99.094h6.826l-1.653 13.546q-.16 1.067-.533 1.494-.374.426-1.174.426-1.44 0-1.653-1.866z'
            style={{}}
          ></path>
          <path d='M493.709 136.534v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M515.522 96.48l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.013.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.814z'
            style={{}}
          ></path>
          <path
            d='M576.642 98.667l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.747.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path d='M625.708 136.534v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M647.521 96.48l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.866.8-.96 0-1.547-.533-.587-.48-1.227-1.814l-17.066-36.213q-.64-1.28-.64-1.973 0-1.013.8-1.76.8-.8 1.866-.8.907 0 1.494.533.586.48 1.226 1.814z'
            style={{}}
          ></path>
          <path
            d='M37.71 136.114q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M119.71 174.674v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M158.484 142.62q.906 0 1.546.854.694.8.694 1.6 0 1.386-1.707 2.293l-13.92 7.093 13.92 7.254q1.707.906 1.707 2.293 0 .8-.694 1.653-.64.8-1.546.8-.747 0-1.6-.48l-22.934-11.573 22.934-11.307q.96-.48 1.6-.48z'
            style={{}}
          ></path>
          <path d='M229.71 174.674v5.333h-32v-5.333z' style={{}}></path>
          <path d='M251.71 174.674v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M268.643 136.807l-17.066 36.213q-.587 1.334-1.227 1.814-.587.533-1.547.533-1.066 0-1.866-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.586-1.333 1.173-1.813.587-.533 1.547-.533 1.066 0 1.866.8.8.746.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path
            d='M299.79 156.38q0-3.68 1.546-8.64 1.547-4.96 3.734-8.533 2.24-3.573 3.946-3.573 1.174 0 1.92.8.8.746.8 1.813 0 .8-.96 2.507-.906 1.653-2.08 3.52-1.12 1.866-2.08 5.226-.906 3.307-.906 7.04 0 7.787 5.386 16.534.64 1.013.64 1.813 0 1.067-.8 1.813-.746.8-1.92.8-1.706 0-3.893-3.413t-3.787-8.48q-1.546-5.067-1.546-9.227z'
            style={{}}
          ></path>
          <path d='M339.71 174.674v5.333h-32v-5.333z' style={{}}></path>
          <path d='M361.71 174.674v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M367.71 136.114q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M411.71 136.114q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M455.709 136.114q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M493.709 174.674v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M489.949 174.887q0-.8.906-2.507.96-1.653 2.08-3.52 1.174-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.386-16.534-.64-1.013-.64-1.813 0-1.067.746-1.813.8-.8 1.974-.8 1.706 0 3.893 3.413t3.733 8.48q1.6 5.067 1.6 9.227 0 3.68-1.546 8.64-1.494 4.906-3.734 8.48-2.186 3.626-3.946 3.626-1.174 0-1.974-.8-.746-.746-.746-1.813z'
            style={{}}
          ></path>
          <path
            d='M543.709 136.114q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
            style={{}}
          ></path>
          <path d='M625.708 174.674v5.333h-32v-5.333z' style={{}}></path>
          <path d='M647.708 174.674v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M664.641 136.807l-17.066 36.213q-.587 1.334-1.227 1.814-.587.533-1.547.533-1.066 0-1.866-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.586-1.333 1.173-1.813.587-.533 1.547-.533 1.066 0 1.866.8.8.746.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path
            d='M37.71 174.254q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M75.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M81.71 174.254q2.667 0 2.667 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
            style={{}}
          ></path>
          <path d='M119.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M119.524 172.76l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path d='M163.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M163.524 172.76l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path d='M207.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path d='M229.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path d='M251.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M257.71 174.254q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M273.523 172.76l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path d='M317.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path d='M339.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path d='M361.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M367.71 174.254q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M405.71 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M411.71 174.254q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M456.189 208.28h-.96q-1.44 0-2.507-1.013-1.013-1.067-1.013-2.507 0-1.44 1.013-2.453 1.067-1.067 2.507-1.067h.96q1.493 0 2.507 1.014 1.013 1.013 1.013 2.506 0 1.494-1.013 2.507-1.014 1.013-2.507 1.013z'
            style={{}}
          ></path>
          <path d='M493.709 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path d='M515.709 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M532.642 174.947l-17.067 36.213q-.586 1.334-1.226 1.814-.587.533-1.547.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.174-1.813.586-.533 1.546-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path
            d='M559.522 172.76l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path d='M603.708 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path d='M625.708 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path d='M647.708 212.814v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M653.708 174.254q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M37.71 212.394q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M119.71 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M141.524 210.9l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path d='M207.71 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path d='M229.71 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path d='M251.71 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path d='M295.71 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path d='M339.71 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path d='M361.71 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M411.71 212.394q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M449.71 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M455.709 212.394q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M493.709 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path d='M559.709 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path d='M603.708 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M609.708 212.394q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M653.708 212.394q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M691.708 250.954v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M37.71 250.534q2.668 0 2.668 3.52V288.4q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M81.71 250.534q2.667 0 2.667 3.52V288.4q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
            style={{}}
          ></path>
          <path d='M119.71 289.094v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M115.95 289.307q0-.8.907-2.507.96-1.653 2.08-3.52 1.174-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.386-16.534-.64-1.013-.64-1.813 0-1.067.746-1.813.8-.8 1.974-.8 1.706 0 3.893 3.413t3.733 8.48q1.6 5.067 1.6 9.227 0 3.68-1.546 8.64-1.494 4.906-3.734 8.48-2.186 3.626-3.946 3.626-1.174 0-1.974-.8-.746-.746-.746-1.813z'
            style={{}}
          ></path>
          <path
            d='M180.644 251.227l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path d='M229.71 289.094v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M251.523 249.04l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.866.8-.96 0-1.547-.533-.587-.48-1.227-1.814l-17.066-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.866-.8.907 0 1.494.533.586.48 1.226 1.813z'
            style={{}}
          ></path>
          <path
            d='M298.296 251.654h6.827L303.47 265.2q-.16 1.067-.534 1.494-.373.426-1.173.426-1.44 0-1.653-1.866z'
            style={{}}
          ></path>
          <path d='M339.71 289.094v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M361.523 249.04l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path
            d='M422.643 251.227l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path d='M471.709 289.094v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M493.522 249.04l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path
            d='M521.709 250.534q2.666 0 2.666 3.52V288.4q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
            style={{}}
          ></path>
          <path
            d='M562.295 251.654h6.827l-1.654 13.546q-.16 1.067-.533 1.494-.373.426-1.173.426-1.44 0-1.654-1.866z'
            style={{}}
          ></path>
          <path d='M603.708 289.094v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M609.708 250.534q2.667 0 2.667 3.52V288.4q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M669.708 289.094v5.333h-32v-5.333z' style={{}}></path>
          <path d='M691.708 289.094v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M697.708 250.534q2.667 0 2.667 3.52V288.4q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M37.71 288.674q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M119.71 327.234v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M158.484 295.18q.906 0 1.546.854.694.8.694 1.6 0 1.386-1.707 2.293l-13.92 7.093 13.92 7.254q1.707.906 1.707 2.293 0 .8-.694 1.653-.64.8-1.546.8-.747 0-1.6-.48l-22.934-11.573 22.934-11.307q.96-.48 1.6-.48z'
            style={{}}
          ></path>
          <path d='M229.71 327.234v5.333h-32v-5.333z' style={{}}></path>
          <path d='M251.71 327.234v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M268.643 289.367l-17.066 36.213q-.587 1.334-1.227 1.814-.587.533-1.547.533-1.066 0-1.866-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.586-1.333 1.173-1.813.587-.533 1.547-.533 1.066 0 1.866.8.8.746.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path
            d='M301.71 288.674q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
            style={{}}
          ></path>
          <path d='M339.71 327.234v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M335.95 327.447q0-.8.906-2.507.96-1.653 2.08-3.52 1.174-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.386-16.534-.64-1.013-.64-1.813 0-1.067.746-1.813.8-.8 1.974-.8 1.706 0 3.893 3.413t3.733 8.48q1.6 5.067 1.6 9.227 0 3.68-1.546 8.64-1.494 4.906-3.734 8.48-2.186 3.626-3.946 3.626-1.174 0-1.974-.8-.746-.746-.746-1.813z'
            style={{}}
          ></path>
          <path
            d='M389.71 288.674q2.666 0 2.666 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M431.79 308.94q0-3.68 1.546-8.64 1.546-4.96 3.733-8.533 2.24-3.573 3.947-3.573 1.173 0 1.92.8.8.746.8 1.813 0 .8-.96 2.507-.907 1.653-2.08 3.52-1.12 1.866-2.08 5.226-.907 3.307-.907 7.04 0 7.787 5.387 16.534.64 1.013.64 1.813 0 1.067-.8 1.813-.747.8-1.92.8-1.707 0-3.894-3.413-2.186-3.413-3.786-8.48-1.547-5.067-1.547-9.227z'
            style={{}}
          ></path>
          <path d='M471.709 327.234v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M467.949 327.447q0-.8.907-2.507.96-1.653 2.08-3.52 1.173-1.866 2.08-5.173.96-3.36.96-7.093 0-7.787-5.387-16.534-.64-1.013-.64-1.813 0-1.067.747-1.813.8-.8 1.973-.8 1.707 0 3.893 3.413 2.187 3.413 3.734 8.48 1.6 5.067 1.6 9.227 0 3.68-1.547 8.64-1.493 4.906-3.733 8.48-2.187 3.626-3.947 3.626-1.173 0-1.973-.8-.747-.746-.747-1.813z'
            style={{}}
          ></path>
          <path
            d='M521.709 288.674q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
            style={{}}
          ></path>
          <path
            d='M565.708 288.674q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M609.708 288.674q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M653.708 288.674q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M691.708 327.234v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M37.71 326.814q2.668 0 2.668 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M75.71 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M81.71 326.814q2.667 0 2.667 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
            style={{}}
          ></path>
          <path
            d='M119.524 325.32l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.546-.533-.587-.48-1.227-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.907 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path d='M163.71 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M163.524 325.32l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path d='M207.71 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path d='M229.71 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path d='M251.71 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M257.71 326.814q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M302.19 360.84h-.96q-1.44 0-2.507-1.013-1.013-1.067-1.013-2.507 0-1.44 1.013-2.453 1.067-1.067 2.507-1.067h.96q1.493 0 2.506 1.014 1.014 1.013 1.014 2.506 0 1.494-1.014 2.507-1.013 1.013-2.506 1.013z'
            style={{}}
          ></path>
          <path d='M339.71 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path d='M361.71 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M378.643 327.507l-17.067 36.213q-.587 1.334-1.227 1.814-.586.533-1.546.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.173-1.813.587-.533 1.547-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path
            d='M405.523 325.32l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path d='M449.71 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path d='M471.709 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path d='M493.709 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M510.642 327.507l-17.067 36.213q-.586 1.334-1.226 1.814-.587.533-1.547.533-1.067 0-1.867-.8-.8-.747-.8-1.76 0-.747.64-2.027l17.12-36.16q.587-1.333 1.174-1.813.586-.533 1.546-.533 1.067 0 1.867.8.8.746.8 1.76 0 .693-.64 1.973z'
            style={{}}
          ></path>
          <path
            d='M521.709 326.814q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
            style={{}}
          ></path>
          <path d='M559.709 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M565.708 326.814q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52-2.666 0-2.666-3.52v-34.346q0-1.707.533-2.614.587-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M625.522 325.32l17.12 36.16q.64 1.28.64 2.027 0 1.013-.8 1.76-.8.8-1.867.8-.96 0-1.547-.533-.586-.48-1.226-1.814l-17.067-36.213q-.64-1.28-.64-1.973 0-1.014.8-1.76.8-.8 1.867-.8.906 0 1.493.533.587.48 1.227 1.813z'
            style={{}}
          ></path>
          <path d='M669.708 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path d='M691.708 365.374v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M697.708 326.814q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path
            d='M257.71 364.954q2.667 0 2.667 3.52v34.346q0 3.52-2.667 3.52t-2.667-3.52v-34.346q0-1.707.534-2.614.586-.906 2.133-.906z'
            style={{}}
          ></path>
          <path d='M295.71 403.514v5.333h-32v-5.333z' style={{}}></path>
          <path
            d='M301.71 364.954q2.666 0 2.666 3.52v34.346q0 3.52-2.666 3.52-2.667 0-2.667-3.52v-34.346q0-1.707.533-2.614.587-.906 2.134-.906z'
            style={{}}
          ></path>
        </g>
      </g>
    </svg>
  )
}
